// src/components/GameBoard/Box.js

import React, { useContext } from 'react';
import ConfigContext from '../../ConfigContext';

const Box = React.memo(({ columnIndex, rowIndex, style, data }) => {
  const { boxes, columnCount, handleBoxClick } = data;
  const index = rowIndex * columnCount + columnIndex;
  const item = boxes[index];
  const config = useContext(ConfigContext);

  if (!item) return null;

  const baseClass =
    'flex items-center justify-center aspect-square rounded-lg cursor-pointer transition-all duration-300 border-2 border-solid';

  const boxStyle = {
    ...style,
    userSelect: 'none',
    outline: 'none',
  };

  if (item.clicked) {
    const teamConfig = config.teams[item.team];
    if (teamConfig) {
      boxStyle.backgroundColor = teamConfig.colors.checkBoxBg;
      boxStyle.borderColor = teamConfig.colors.checkBoxBorder;
      boxStyle.color = teamConfig.colors.checkBoxText;
    }
  } else {
    boxStyle.backgroundColor = '#F3F4F6'; // gray-100
    boxStyle.borderColor = '#D1D5DB'; // gray-300
  }

  return (
    <button
      style={boxStyle}
      disabled={item.clicked}
      onClick={() => handleBoxClick(index)}
      className={baseClass}
      aria-label={item.clicked ? 'Box clicked' : 'Clickable box'}
    >
      {item.clicked ? '✓' : null}
    </button>
  );
});

export default Box;