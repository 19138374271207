// src/App.js

import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { database } from './firebase';
import { ref, onValue } from 'firebase/database';
import UnderConstruction from './components/UnderConstruction';
import Header from './components/Header';
import GameSelection from './components/GameSelection';
import TeamSelection from './components/TeamSelection';
import GameBoard from './components/GameBoard/GameBoard';
import GameHistory from './components/GameHistory';
import CreateGame from './components/CreateGame';

function App() {
  const [siteUnderConstruction, setSiteUnderConstruction] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const siteSettingsRef = ref(database, 'siteSettings/site_under_construction');
    const unsubscribe = onValue(
      siteSettingsRef,
      (snapshot) => {
        const isUnderConstruction = snapshot.val();
        setSiteUnderConstruction(isUnderConstruction);
      },
      (error) => {
        console.error('Error fetching site under construction flag:', error);
        setSiteUnderConstruction(false); // Default to false on error
      }
    );

    return () => unsubscribe(); // Clean up listener on component unmount
  }, []);

  const handleBuyMeACoffee = () => {
    window.open('https://buymeacoffee.com/clickwars', '_blank');
  };

  if (siteUnderConstruction === null) {
    return <div>Loading...</div>; // Show loading state while fetching the flag
  }

  if (siteUnderConstruction) {
    return <UnderConstruction />; // Show "Under Construction" page if flag is true
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4 flex flex-col">
      <Header />
      <Routes>
        <Route path="/" element={<GameSelection />} />
        <Route path="/game/:gameSlug/join" element={<TeamSelection />} />
        <Route path="/game/:gameSlug/play" element={<GameBoard />} />
        <Route path="/game/:gameSlug/history" element={<GameHistory />} />
        <Route path="/create-game" element={<CreateGame />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <div
        className="fixed bottom-4 right-4 z-50 hidden md:block"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <button
          onClick={handleBuyMeACoffee}
          className="bg-yellow-500 text-white rounded-full shadow-lg flex items-center justify-start overflow-hidden transition-all duration-300 ease-in-out"
          style={{
            width: isHovered ? '180px' : '50px',
            height: '50px',
          }}
        >
          <span className="text-2xl flex-shrink-0 w-[50px] flex justify-center items-center">☕</span>
          <span
            className="whitespace-nowrap transition-all duration-300 ease-in-out"
            style={{
              opacity: isHovered ? 1 : 0,
              transform: `translateX(${isHovered ? '0' : '-20px'})`,
              maxWidth: isHovered ? '130px' : '0',
            }}
          >
            Buy Me a Coffee
          </span>
        </button>
      </div>
    </div>
  );
}

export default App;
