// src/components/GameSelection.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAvailableGames, clearTeamSelection } from '../store/gameLogic';

function GameSelection() {
  const navigate = useNavigate();
  const [games, setGames] = useState([]);

  useEffect(() => {
    getAvailableGames().then((gamesList) => {
      setGames(gamesList);
    });
  }, []);

  const handleGameSelect = (gameSlug, gameId) => {
    clearTeamSelection(gameId);
    navigate(`/game/${gameSlug}/join`);
  };

  const handleCreateNewGame = () => {
    navigate('/create-game');
  };

  const handleBuyMeACoffee = () => {
    window.open('https://buymeacoffee.com/clickwars', '_blank');
  };

  if (games.length === 0) {
    return <div>Loading games...</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <h2 className="text-2xl mb-4">Select a Game:</h2>
      <div className="flex flex-col space-y-4 w-full max-w-md">
        {games.map((game) => (
          <button
            key={game.id}
            onClick={() => handleGameSelect(game.slug, game.id)}
            className="w-full px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            {game.name}
          </button>
        ))}

        {process.env.NODE_ENV === 'development' && (
          <button
            onClick={handleCreateNewGame}
            className="w-full mt-8 px-6 py-3 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
          >
            Create New Game
          </button>
        )}

        <button
          onClick={handleBuyMeACoffee}
          className="w-full mt-8 px-6 py-3 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition-colors"
        >
          Buy Me a Coffee
        </button>
      </div>
    </div>
  );
}

export default GameSelection;
