// src/components/GameBoard/CountsDisplay.js

import React, { useContext } from 'react';
import ConfigContext from '../../ConfigContext';

const CountsDisplay = ({ counts, remainingBoxes }) => {
  const config = useContext(ConfigContext);
  const teamsArray = config.TEAMS;

  // Calculate the majority needed to win
  const totalBoxes = config.totalBoxes;
  const majority = Math.ceil(totalBoxes / 2);

  // Calculate boxes needed to win for each team
  const boxesNeeded = teamsArray.map((team) => {
    const needed = majority - counts[team.key];
    return {
      key: team.key,
      name: team.name,
      needed: needed > 0 ? needed : 0,
      color: team.colors.counterText,
      count: counts[team.key],
    };
  });

  return (
    <div className="mt-4 flex-none w-full px-4">
      <div className="flex flex-col md:flex-row justify-center items-center">
        {boxesNeeded.map((team) => (
          <div key={team.key} className="flex items-center mx-4 mb-2 md:mb-0">
            <span className="font-semibold" style={{ color: team.color }}>
              {team.name}:
            </span>
            <span className="ml-2">
              {team.count} clicks
            </span>
            {team.needed > 0 && (
              <span className="ml-2" style={{ color: team.color }}>
                ({team.needed} to win)
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountsDisplay;