// src/components/CreateGame.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addNewGame } from '../store/gameLogic';

function CreateGame() {
  const navigate = useNavigate();
  const [gameName, setGameName] = useState('');
  const [teams, setTeams] = useState([
    {
      name: '',
      colors: {
        checkBoxBg: '#000000',
        checkBoxBorder: '#FFFFFF',
        checkBoxText: '#FFFFFF',
        scoreBarBg: '#000000',
        scoreBarText: '#FFFFFF',
        counterText: '#FFFFFF',
        teamSelectionBtnBg: '#000000',
        teamSelectionBtnHvBg: '#333333',
        teamSelectionBtnText: '#FFFFFF'
      }
    },
    {
      name: '',
      colors: {
        checkBoxBg: '#FFFFFF',
        checkBoxBorder: '#000000',
        checkBoxText: '#000000',
        scoreBarBg: '#FFFFFF',
        scoreBarText: '#000000',
        counterText: '#000000',
        teamSelectionBtnBg: '#FFFFFF',
        teamSelectionBtnHvBg: '#CCCCCC',
        teamSelectionBtnText: '#000000'
      }
    }
  ]);
  const [config, setConfig] = useState({
    totalBoxes: 100,
    boxSize: 50,
    gridPadding: 32,
    headerHeight: 100,
    footerHeight: 150,
    countdownDuration: 600
  });

  useEffect(() => {
    document.body.classList.add('create-game-page');
    return () => {
      document.body.classList.remove('create-game-page');
    };
  }, []);

  const handleTeamChange = (index, field, value) => {
    const newTeams = [...teams];
    if (field.startsWith('colors.')) {
      const colorField = field.split('.')[1];
      newTeams[index].colors[colorField] = value;
    } else {
      newTeams[index][field] = value;
    }
    setTeams(newTeams);
  };

  const handleConfigChange = (field, value) => {
    setConfig({ ...config, [field]: parseInt(value, 10) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newGame = {
      name: gameName,
      slug: gameName.toLowerCase().replace(/\s+/g, '-'),
      status: 'active',
      createdAt: Date.now(),
      gameState: 'active',
      counts: {
        team1: 0,
        team2: 0
      },
      config: {
        ...config
      },
      teams: teams.reduce((acc, team, index) => {
        const teamKey = `team${index + 1}`;
        acc[teamKey] = {
          name: team.name,
          colors: team.colors
        };
        return acc;
      }, {})
    };

    try {
      await addNewGame(newGame);
      navigate('/');
    } catch (error) {
      console.error('Failed to create new game:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    <div className="create-game-container max-w-2xl mx-auto pb-20">
      <h2 className="text-2xl font-bold mb-4">Create New Game</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block mb-2">Game Name:</label>
          <input
            type="text"
            value={gameName}
            onChange={(e) => setGameName(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>

        {teams.map((team, index) => (
          <div key={index} className="border p-4 rounded">
            <h3 className="font-bold mb-2">Team {index + 1}</h3>
            <div className="space-y-2">
              <input
                type="text"
                value={team.name}
                onChange={(e) => handleTeamChange(index, 'name', e.target.value)}
                placeholder="Team Name"
                className="w-full px-3 py-2 border rounded"
                required
              />
              <div className="grid grid-cols-2 gap-2">
                {Object.entries(team.colors).map(([colorKey, colorValue]) => (
                  <div key={colorKey}>
                    <label className="block">{colorKey}:</label>
                    <input
                      type="color"
                      value={colorValue}
                      onChange={(e) => handleTeamChange(index, `colors.${colorKey}`, e.target.value)}
                      className="w-full"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        <div className="border p-4 rounded">
          <h3 className="font-bold mb-2">Game Configuration</h3>
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(config).map(([key, value]) => (
              <div key={key}>
                <label className="block">{key}:</label>
                <input
                  type="number"
                  value={value}
                  onChange={(e) => handleConfigChange(key, e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>
            ))}
          </div>
        </div>

        <button type="submit" className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
          Create Game
        </button>
      </form>
    </div>
  );
}

export default CreateGame;