// src/components/TeamSelection.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { selectTeam, getGameBySlug, clearTeamSelection } from '../store/gameLogic';

function TeamSelection() {
  const { gameSlug } = useParams();
  const navigate = useNavigate();
  const [hoveredTeamKey, setHoveredTeamKey] = useState(null);
  const [config, setConfig] = useState(null);
  const [gameId, setGameId] = useState(null);

  useEffect(() => {
    // Fetch the game data using the slug
    getGameBySlug(gameSlug).then((gameData) => {
      if (!gameData) {
        navigate('/', { replace: true });
        return;
      }

      const { id } = gameData;
      setGameId(id);
      clearTeamSelection(id);

      const { config: gameConfig, teams } = gameData;
      const teamsArray = Object.keys(teams).map((key) => ({
        key,
        ...teams[key],
      }));

      const fullConfig = {
        ...gameConfig,
        TEAMS: teamsArray,
        teams,
      };

      setConfig(fullConfig);
    });
  }, [navigate, gameSlug]);

  const handleTeamSelect = (teamKey) => {
    selectTeam(gameId, teamKey);
    navigate(`/game/${gameSlug}/play`);
  };

  const handleGameHistory = () => {
    navigate(`/game/${gameSlug}/history`);
  };

  if (!config) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <h2 className="text-2xl mb-4">Choose your team:</h2>
      <div className="flex justify-center space-x-4">
        {config.TEAMS.map((team) => {
          const isHovered = hoveredTeamKey === team.key;
          const backgroundColor = isHovered
            ? team.colors.teamSelectionBtnHvBg || team.colors.teamSelectionBtnBg
            : team.colors.teamSelectionBtnBg;
          const color = team.colors.teamSelectionBtnText;

          return (
            <button
              key={team.key}
              onClick={() => handleTeamSelect(team.key)}
              onMouseEnter={() => setHoveredTeamKey(team.key)}
              onMouseLeave={() => setHoveredTeamKey(null)}
              className="px-6 py-3 rounded-lg transition-colors"
              style={{
                backgroundColor: backgroundColor,
                color: color,
              }}
            >
              Join Team {team.name}
            </button>
          );
        })}
      </div>

      {/* Add the Game History button */}
      <div className="mt-6">
        <button
          onClick={handleGameHistory}
          className="px-6 py-3 rounded-lg bg-gray-500 hover:bg-gray-600 text-white transition-colors"
        >
          Game History
        </button>
      </div>
    </div>
  );
}

export default TeamSelection;
