// src/components/GameBoard/GameBoard.js

import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FixedSizeGrid as Grid } from 'react-window';
import {
  getTeam,
  getBoxes,
  clickBox,
  getGameBySlug,
  listenToGameState,
  listenToWinningTeam,
  listenToCounts,
} from '../../store/gameLogic';
import { ref, get } from 'firebase/database';
import { database } from '../../firebase';
import ConfigContext from '../../ConfigContext';
import Box from './Box';
import ScoreBar from './ScoreBar';
import CountsDisplay from './CountsDisplay';
import WinScreen from './WinScreen';
import useGridSize from './useGridSize';

function GameBoard() {
  const { gameSlug } = useParams();
  const navigate = useNavigate();
  const [team, setTeam] = useState(null);
  const [boxes, setBoxes] = useState([]);
  const [config, setConfig] = useState(null);
  const [lastSearchedIndex, setLastSearchedIndex] = useState(-1);
  const [winningTeam, setWinningTeam] = useState(null);
  const [gameState, setGameState] = useState('active');
  const [counts, setCounts] = useState({});
  const [gameId, setGameId] = useState(null);
  const gridRef = useRef(null);
  const gridSize = useGridSize(config);
  const prevGameStateRef = useRef(gameState);

  const resetLocalState = useCallback(() => {
    setBoxes((prevBoxes) => prevBoxes.map(box => ({ ...box, clicked: false, team: null })));
    setWinningTeam(null);
    setLastSearchedIndex(-1);
    // Note: We're not resetting counts here as they should be updated from Firebase
  }, []);

  useEffect(() => {
    let unsubscribeBoxes, unsubscribeGameState, unsubscribeWinningTeam, unsubscribeCounts;

    const setupGame = async () => {
      const gameData = await getGameBySlug(gameSlug);
      if (!gameData) {
        navigate('/', { replace: true });
        return;
      }

      const { id } = gameData;
      setGameId(id);

      const currentTeam = getTeam(id);
      setTeam(currentTeam);

      if (!currentTeam) {
        navigate(`/game/${gameSlug}/join`, { replace: true });
        return;
      }

      // Fetch the config
      const { config: gameConfig, teams } = gameData;

      // Process gameData to create config object
      const teamsArray = Object.keys(teams).map((key) => ({
        key,
        ...teams[key],
      }));

      const fullConfig = {
        ...gameConfig,
        TEAMS: teamsArray,
        teams,
        gameId: id,
      };

      setConfig(fullConfig);

      // Fetch initial boxes data from Firebase
      const totalBoxes = gameConfig.totalBoxes;

      // Fetch the initial state of the boxes from Firebase
      const boxesRef = ref(database, `games/${id}/boxes`);
      const boxesSnapshot = await get(boxesRef);
      const boxesData = boxesSnapshot.val() || {};

      // Initialize boxes array with the data from Firebase
      const initialBoxes = Array.from({ length: totalBoxes }, (_, index) => {
        const boxData = boxesData[index] || { clicked: false, team: null };
        return {
          index,
          ...boxData,
        };
      });
      setBoxes(initialBoxes);

      // Set up listeners
      unsubscribeBoxes = getBoxes(id, (boxesData) => {
        setBoxes((prevBoxes) => {
          const newBoxes = [...prevBoxes];
          Object.entries(boxesData).forEach(([index, boxData]) => {
            newBoxes[parseInt(index)] = {
              index: parseInt(index),
              ...boxData,
            };
          });
          return newBoxes;
        });
      });

      unsubscribeGameState = listenToGameState(id, (newState) => {
        setGameState((prevState) => {
          if (prevState === 'over' && newState === 'active') {
            resetLocalState();
          }
          prevGameStateRef.current = newState;
          return newState;
        });
      });

      unsubscribeWinningTeam = listenToWinningTeam(id, (teamKey) => {
        setWinningTeam(teamKey);
      });

      unsubscribeCounts = listenToCounts(id, (countsData) => {
        setCounts(countsData);
      });
    };

    setupGame();

    return () => {
      if (unsubscribeBoxes) unsubscribeBoxes();
      if (unsubscribeGameState) unsubscribeGameState();
      if (unsubscribeWinningTeam) unsubscribeWinningTeam();
      if (unsubscribeCounts) unsubscribeCounts();
    };
  }, [navigate, gameSlug, resetLocalState]);

  const handleBoxClick = useCallback(
    (index) => {
      const box = boxes[index];
      if (box.clicked || gameState !== 'active') return;

      // Update locally for immediate feedback
      setBoxes((prevBoxes) => {
        const newBoxes = [...prevBoxes];
        newBoxes[index] = {
          ...box,
          clicked: true,
          team,
        };
        return newBoxes;
      });

      // Update Firebase
      clickBox(gameId, index, team);
    },
    [gameId, team, boxes, gameState]
  );

  // Calculate percentages
  const totalCounts = Object.values(counts).reduce((sum, val) => sum + val, 0);

  const percentages = useMemo(() => {
    const percentages = {};
    if (config && config.TEAMS) {
      if (totalCounts === 0) {
        const defaultPercentage = 100 / config.TEAMS.length;
        config.TEAMS.forEach((team) => {
          percentages[team.key] = defaultPercentage;
        });
      } else {
        config.TEAMS.forEach((team) => {
          percentages[team.key] = ((counts[team.key] || 0) / totalCounts) * 100;
        });
      }
    }
    return percentages;
  }, [counts, config, totalCounts]);

  const totalBoxes = config ? config.totalBoxes : 0;
  const remainingBoxes = totalBoxes - totalCounts;

  // Early returns after hooks
  const isLoading = !team || !config || boxes.length === 0;

  const columnCount = useMemo(() => {
    return Math.floor(gridSize.width / (config ? config.boxSize : 1));
  }, [gridSize.width, config]);

  const rowCount = useMemo(() => {
    return Math.ceil(totalBoxes / columnCount);
  }, [totalBoxes, columnCount]);

  const itemData = useMemo(
    () => ({
      boxes,
      columnCount,
      handleBoxClick,
    }),
    [boxes, columnCount, handleBoxClick]
  );

  // Function to scroll to the next unclicked box
  const scrollToNextUnclicked = useCallback(() => {
    const startIndex = lastSearchedIndex + 1;
    const totalBoxes = boxes.length;

    let nextUnclickedIndex = -1;

    // Search from the current index to the end
    for (let i = startIndex; i < totalBoxes; i++) {
      if (!boxes[i].clicked) {
        nextUnclickedIndex = i;
        break;
      }
    }

    // If not found, wrap around and search from the beginning
    if (nextUnclickedIndex === -1) {
      for (let i = 0; i < startIndex; i++) {
        if (!boxes[i].clicked) {
          nextUnclickedIndex = i;
          break;
        }
      }
    }

    if (nextUnclickedIndex === -1) {
      alert('No more unclicked boxes.');
      return;
    }

    setLastSearchedIndex(nextUnclickedIndex);

    // Calculate rowIndex and columnIndex
    const rowIndex = Math.floor(nextUnclickedIndex / columnCount);
    const columnIndex = nextUnclickedIndex % columnCount;

    // Scroll to the item
    if (gridRef.current) {
      gridRef.current.scrollToItem({
        rowIndex,
        columnIndex,
      });
    }
  }, [boxes, columnCount, lastSearchedIndex]);

  // Add event listener for custom event
  useEffect(() => {
    const handleEvent = () => {
      scrollToNextUnclicked();
    };

    window.addEventListener('scrollToNextUnclicked', handleEvent);

    return () => {
      window.removeEventListener('scrollToNextUnclicked', handleEvent);
    };
  }, [scrollToNextUnclicked]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ConfigContext.Provider value={config}>
      <div className="flex flex-col h-full">
        <ScoreBar percentages={percentages} />

        <div className="flex-grow overflow-hidden">
          <Grid
            className="mx-auto grid-scrollbar"
            width={gridSize.width}
            height={gridSize.height}
            columnCount={columnCount}
            rowCount={rowCount}
            columnWidth={config.boxSize}
            rowHeight={config.boxSize}
            itemData={itemData}
            ref={gridRef}
          >
            {Box}
          </Grid>
        </div>

        <CountsDisplay counts={counts} remainingBoxes={remainingBoxes} />

        {/* Win Screen */}
        {winningTeam && (
          <WinScreen winningTeam={winningTeam} />
        )}
      </div>
    </ConfigContext.Provider>
  );
}

export default GameBoard;
