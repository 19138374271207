// src/components/Header.js

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation, useMatch } from 'react-router-dom';
import { getGameBySlug, getTeam, clearTeamSelection } from '../store/gameLogic';

function Header() {
  // State hooks
  const [menuOpen, setMenuOpen] = useState(false);
  const [gameTitle, setGameTitle] = useState('Click Wars!');
  const [buttonStyles, setButtonStyles] = useState({
    backgroundColor: '#6B7280',
    hoverBackgroundColor: '#4B5563',
    textColor: '#FFFFFF',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [titleFontSize, setTitleFontSize] = useState(48); // Default font size
  const [gameId, setGameId] = useState(null);

  // Refs for DOM elements
  const headerRef = useRef(null);
  const titleRef = useRef(null);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);

  // Router hooks
  const navigate = useNavigate();
  const location = useLocation();

  // Match hooks for different game routes
  const matchGamePlay = useMatch('/game/:gameSlug/play');
  const matchGameJoin = useMatch('/game/:gameSlug/join');
  const matchGameHistory = useMatch('/game/:gameSlug/history');
  const gameSlug = matchGamePlay?.params.gameSlug || matchGameJoin?.params.gameSlug || matchGameHistory?.params.gameSlug;

  // Effect hook to fetch game data and set up styles
  useEffect(() => {
    let isMounted = true;

    async function fetchGameData() {
      if (gameSlug) {
        const gameData = await getGameBySlug(gameSlug);
        if (gameData && isMounted) {
          const { id } = gameData;
          setGameId(id);
          // Set game title
          const teamNames = Object.values(gameData.teams).map((team) => team.name);
          const title = teamNames.join(' vs ');
          setGameTitle(title);

          // Set button styles based on selected team
          const selectedTeamKey = getTeam(id);
          if (selectedTeamKey && gameData.teams[selectedTeamKey]) {
            const teamColors = gameData.teams[selectedTeamKey].colors;
            setButtonStyles({
              backgroundColor: teamColors.teamSelectionBtnBg || '#6B7280',
              hoverBackgroundColor: teamColors.teamSelectionBtnHvBg || '#4B5563',
              textColor: teamColors.teamSelectionBtnText || '#FFFFFF',
            });
          } else {
            setButtonStyles({
              backgroundColor: '#6B7280',
              hoverBackgroundColor: '#4B5563',
              textColor: '#FFFFFF',
            });
          }
        }
      } else {
        // Default title and styles if no game is selected
        setGameTitle('Click Wars!');
        setButtonStyles({
          backgroundColor: '#6B7280',
          hoverBackgroundColor: '#4B5563',
          textColor: '#FFFFFF',
        });
      }
      if (isMounted) setIsLoading(false);
    }

    fetchGameData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [gameSlug, location.pathname]);

  // Effect hook to close menu on route change
  useEffect(() => {
    setMenuOpen(false);
  }, [location.pathname]);

  // Effect hook to handle dynamic text resizing
  useEffect(() => {
    const resizeText = () => {
      if (headerRef.current && titleRef.current && leftButtonRef.current && rightButtonRef.current) {
        const headerWidth = headerRef.current.offsetWidth;
        const leftButtonWidth = leftButtonRef.current.offsetWidth;
        const rightButtonWidth = rightButtonRef.current.offsetWidth;
        const availableWidth = headerWidth - leftButtonWidth - rightButtonWidth - 50; // 40px for padding

        let fontSize = gameTitle === 'Click Wars!' ? 48 : 34;
        titleRef.current.style.fontSize = `${fontSize}px`;

        while (titleRef.current.offsetWidth > availableWidth && fontSize > 12) {
          fontSize--;
          titleRef.current.style.fontSize = `${fontSize}px`;
        }

        setTitleFontSize(fontSize);
      }
    };

    resizeText();
    window.addEventListener('resize', resizeText);

    return () => {
      window.removeEventListener('resize', resizeText);
    };
  }, [gameTitle]);

  // Handler functions
  const handleChangeTeam = () => {
    if (gameSlug && gameId) {
      clearTeamSelection(gameId);
      setMenuOpen(false);
      navigate(`/game/${gameSlug}/join`, { replace: true });
    } else {
      navigate('/', { replace: true });
    }
  };

  const handleGoToGameLobby = () => {
    setMenuOpen(false);
    navigate('/', { replace: true });
  };

  const handleBuyMeACoffee = () => {
    window.open('https://buymeacoffee.com/clickwars', '_blank');
    setMenuOpen(false);
  };

  const handleScrollToNextUnclicked = (e) => {
    const event = new CustomEvent('scrollToNextUnclicked');
    window.dispatchEvent(event);
  };

  // Route checks
  const isGamePage = location.pathname.includes('/game/');
  const isTeamSelectionPage = location.pathname.includes('/join');
  const isHistoryPage = location.pathname.includes('/history');
  const isGameLobby = location.pathname === '/';

  // Common classes
  const buttonClass = `px-2 py-1 rounded transition-colors duration-200`;

  // Loading state render
  if (isLoading) {
    return (
      <header ref={headerRef} className="relative flex justify-center items-center mb-4 py-4">
        <h1 ref={titleRef} style={{ fontSize: `${titleFontSize}px` }} className="font-bold text-center px-2">
          {gameTitle}
        </h1>
      </header>
    );
  }

  // Main render
  return (
    <header ref={headerRef} className="relative flex justify-center items-center mb-0 py-4" style={{ zIndex: 50 }}>
      {/* Scroll to next unclicked box button (only on game play page) */}
      <div ref={leftButtonRef} className="absolute left-0 top-1/2 transform -translate-y-1/2">
        {isGamePage && !isTeamSelectionPage && !isHistoryPage && (
          <button
            onClick={handleScrollToNextUnclicked}
            className={`${buttonClass}`}
            style={{
              backgroundColor: buttonStyles.backgroundColor,
              color: buttonStyles.textColor,
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = buttonStyles.hoverBackgroundColor;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
            }}
            onTouchStart={(e) => {
              e.currentTarget.style.backgroundColor = buttonStyles.hoverBackgroundColor;
            }}
            onTouchEnd={(e) => {
              e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
            }}
            onTouchCancel={(e) => {
              e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
            }}
          >
            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M10 14a1 1 0 01-.7-.3l-5-5a1 1 0 111.4-1.4L10 11.58l4.3-4.3a1 1 0 011.4 1.4l-5 5a1 1 0 01-.7.3z" clipRule="evenodd" />
            </svg>
          </button>
        )}
      </div>

      {/* Menu button and dropdown */}
      <div ref={rightButtonRef} className="absolute right-0 top-1/2 transform -translate-y-1/2" style={{ zIndex: 51 }}>
        <button
          onClick={() => setMenuOpen(!menuOpen)}
          className={`${buttonClass} relative`}
          style={{
            backgroundColor: buttonStyles.backgroundColor,
            color: buttonStyles.textColor,
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = buttonStyles.hoverBackgroundColor;
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
          }}
          onTouchStart={(e) => {
            e.currentTarget.style.backgroundColor = buttonStyles.hoverBackgroundColor;
          }}
          onTouchEnd={(e) => {
            e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
          }}
          onTouchCancel={(e) => {
            e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
          }}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>

        {menuOpen && (
          <>
            {/* Overlay to close menu when clicking outside */}
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-40"
              onClick={() => setMenuOpen(false)}
            />
            {/* Dropdown menu */}
            <div
              className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg z-50"
            >
              {/* Change Team option (only on game play page) */}
              {isGamePage && !isTeamSelectionPage && !isHistoryPage && (
                <button
                  onClick={handleChangeTeam}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  style={{
                    backgroundColor: buttonStyles.backgroundColor,
                    color: buttonStyles.textColor,
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = buttonStyles.hoverBackgroundColor;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
                  }}
                >
                  Change Team
                </button>
              )}
              {/* Game Lobby option (not on game lobby page) */}
              {!isGameLobby && (
                <button
                  onClick={handleGoToGameLobby}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  style={{
                    backgroundColor: buttonStyles.backgroundColor,
                    color: buttonStyles.textColor,
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor = buttonStyles.hoverBackgroundColor;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
                  }}
                >
                  Game Lobby
                </button>
              )}
              {/* Buy Me a Coffee option (always present) */}
              <button
                onClick={handleBuyMeACoffee}
                className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                style={{
                  backgroundColor: buttonStyles.backgroundColor,
                  color: buttonStyles.textColor,
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = buttonStyles.hoverBackgroundColor;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = buttonStyles.backgroundColor;
                }}
              >
                Buy Me a Coffee ☕
              </button>
            </div>
          </>
        )}
      </div>

      {/* Game title with dynamic font size */}
      <h1 ref={titleRef} style={{ fontSize: `${titleFontSize}px` }} className="font-bold text-center px-2">
        {gameTitle}
      </h1>
    </header>
  );
}

export default Header;
