// src/components/UnderConstruction.js

import React from 'react';

function UnderConstruction() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Site Under Construction</h1>
      <p className="text-lg">
        We're working hard to bring you a better experience. Please check back later.
      </p>
    </div>
  );
}

export default UnderConstruction;
