// src/components/GameHistory.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getGameHistoryBySlug } from '../store/gameLogic';

function GameHistory() {
  const { gameSlug } = useParams();
  const navigate = useNavigate();
  const [history, setHistory] = useState(null);
  //const [gameId, setGameId] = useState(null);


  useEffect(() => {
    getGameHistoryBySlug(gameSlug).then(({ historyData, gameId }) => {
      if (!historyData) {
        navigate(`/game/${gameSlug}/join`, { replace: true });
        return;
      }
      setHistory(historyData);
      //setGameId(gameId);

    });
  }, [gameSlug, navigate]);

  if (!history) {
    return <div>Loading...</div>;
  }

  const formatDuration = (startTime, endTime) => {
    const duration = endTime - startTime;
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  return (
    <div className="flex flex-col h-screen p-4">
      <h2 className="text-2xl mb-4">Game History</h2>
      <button
        onClick={() => navigate(`/game/${gameSlug}/join`)}
        className="mb-4 px-4 py-2 rounded bg-gray-500 hover:bg-gray-600 text-white"
      >
        Back to Team Selection
      </button>
      <div className="flex-grow overflow-auto">
        <div className="space-y-4 pb-28">
          {history.map((game, index) => (
            <div key={index} className="border p-4 rounded shadow">
              <h3 className="text-xl font-bold">Game {history.length - index}</h3>
              <p>
                <strong>Winning Team:</strong> {game.winningTeamName}
              </p>
              <p>
                <strong>Start Time:</strong> {new Date(game.gameStartTime).toLocaleString(undefined, { timeZoneName: 'short' })}
              </p>
              <p>
                <strong>Game Over Time:</strong> {new Date(game.gameOverTime).toLocaleString(undefined, { timeZoneName: 'short' })}
              </p>
              <p>
                <strong>Game Duration:</strong> {formatDuration(game.gameStartTime, game.gameOverTime)}
              </p>
              <p>
                <strong>Clicks:</strong>
              </p>
              <ul className="list-disc list-inside">
                {Object.entries(game.counts).map(([teamKey, count]) => (
                  <li key={teamKey}>
                    {game.teams[teamKey].name}: {count}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GameHistory;
