// src/components/GameBoard/useGridSize.js

import { useState, useEffect } from 'react';

const useGridSize = (config) => {
  const [gridSize, setGridSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (!config) return;

    const handleResize = () => {
      const width = window.innerWidth - config.gridPadding;
      const height = window.innerHeight - config.headerHeight - config.footerHeight;
      setGridSize({ width, height });
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [config]);

  return gridSize;
};

export default useGridSize;
