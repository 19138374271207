// src/store/gameLogic.js

import { database } from '../firebase';
import { ref, onValue, get, runTransaction, push, set } from 'firebase/database';

export function selectTeam(gameId, team) {
  localStorage.setItem(`team_${gameId}`, team);
}

export function getTeam(gameId) {
  return localStorage.getItem(`team_${gameId}`) || null;
}

export function clearTeamSelection(gameId) {
  localStorage.removeItem(`team_${gameId}`);
}

export function getAvailableGames() {
  const gamesRef = ref(database, 'games');
  return get(gamesRef).then((snapshot) => {
    if (snapshot.exists()) {
      const gamesObj = snapshot.val();
      const gamesList = Object.keys(gamesObj).map((gameId) => {
        const game = gamesObj[gameId];
        return {
          id: gameId,
          ...game,
        };
      });
      return gamesList;
    } else {
      console.error('No games available.');
      return [];
    }
  });
}

export function getGameBySlug(gameSlug) {
  const gamesRef = ref(database, 'games');
  return get(gamesRef).then((snapshot) => {
    if (snapshot.exists()) {
      const gamesObj = snapshot.val();
      for (let gameId in gamesObj) {
        if (gamesObj[gameId].slug === gameSlug) {
          return { id: gameId, ...gamesObj[gameId] };
        }
      }
    }
    console.error(`Game with slug ${gameSlug} does not exist.`);
    return null;
  });
}

export function getGameConfig(gameId) {
  const gameConfigRef = ref(database, `games/${gameId}`);
  return get(gameConfigRef).then((snapshot) => {
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      console.error(`Game with ID ${gameId} does not exist.`);
      return null;
    }
  });
}

export function getBoxes(gameId, callback) {
  const boxesRef = ref(database, `games/${gameId}/boxes`);
  return onValue(boxesRef, (snapshot) => {
    const boxesObj = snapshot.val() || {};
    callback(boxesObj);
  });
}

export function clickBox(gameId, index, team) {
  if (!team) {
    alert('Please select a team before clicking a box.');
    return;
  }

  const boxRef = ref(database, `games/${gameId}/boxes/${index}`);

  runTransaction(boxRef, (currentData) => {
    if (currentData === null) {
      // Box hasn't been clicked yet
      return {
        clicked: true,
        team,
      };
    } else {
      // Box already clicked
      return; // Abort the transaction
    }
  }).then(() => {
    // Transaction completed successfully
  }).catch((error) => {
    console.error('Error clicking box:', error);
    alert('An error occurred while clicking the box.');
  });
}

export function listenToGameState(gameId, callback) {
  const gameStateRef = ref(database, `games/${gameId}/gameState`);
  return onValue(gameStateRef, (snapshot) => {
    callback(snapshot.val());
  });
}

export function listenToWinningTeam(gameId, callback) {
  const winningTeamRef = ref(database, `games/${gameId}/winningTeam`);
  return onValue(winningTeamRef, (snapshot) => {
    callback(snapshot.val());
  });
}

export function listenToCounts(gameId, callback) {
  const countsRef = ref(database, `games/${gameId}/counts`);
  return onValue(countsRef, (snapshot) => {
    callback(snapshot.val() || {});
  });
}

export function listenToGameRestartTime(gameId, callback) {
  const gameRestartTimeRef = ref(database, `games/${gameId}/gameRestartTime`);
  return onValue(gameRestartTimeRef, (snapshot) => {
    callback(snapshot.val());
  });
}

export async function getGameHistory(gameId) {
  const gameHistoryRef = ref(database, `games/${gameId}/gameHistory`);
  const gameRef = ref(database, `games/${gameId}`);
  const [historySnapshot, gameSnapshot] = await Promise.all([
    get(gameHistoryRef),
    get(gameRef),
  ]);

  if (historySnapshot.exists() && gameSnapshot.exists()) {
    const historyData = historySnapshot.val();
    const gameData = gameSnapshot.val();
    const teams = gameData.teams;

    const historyArray = Object.values(historyData)
      .reverse() // Optional: to show the most recent games first
      .map((gameEntry) => ({
        ...gameEntry,
        winningTeamName: teams[gameEntry.winningTeam]?.name || 'Unknown',
        teams,
      }));

    return historyArray;
  } else {
    console.error('No game history available.');
    return [];
  }
}

// New function to get game history by slug
export async function getGameHistoryBySlug(gameSlug) {
  const gameData = await getGameBySlug(gameSlug);
  if (!gameData) {
    return { historyData: null, gameId: null };
  }
  const { id: gameId } = gameData;
  const historyData = await getGameHistory(gameId);
  return { historyData, gameId };
}

export const addNewGame = async (newGame) => {
  const gamesRef = ref(database, 'games');
  const newGameRef = push(gamesRef);
  const gameId = newGameRef.key;

  const gameData = {
    ...newGame,
    id: gameId
  };

  await set(newGameRef, gameData);
  return gameId;
};
