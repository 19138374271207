// src/components/GameBoard/ScoreBar.js

import React, { useContext } from 'react';
import ConfigContext from '../../ConfigContext';

const ScoreBar = ({ percentages }) => {
  const config = useContext(ConfigContext);
  return (
    <div className="w-full h-10 mb-4 flex-none flex rounded overflow-hidden border border-gray-300">
      {config.TEAMS.map((team) => {
        const percentage = percentages[team.key];
        if (percentage <= 0) return null;
        const style = {
          width: `${percentage}%`,
          backgroundColor: team.colors.scoreBarBg,
          color: team.colors.scoreBarText,
          transition: 'width 0.5s ease-in-out',
          display: 'flex',
          alignItems: 'center',
          justifyContent: percentage < 10 ? 'center' : 'space-between',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
        };
        return (
          <div key={team.key} style={style}>
            {percentage >= 5 && (
              <span className="font-semibold"
              style={{
                lineHeight: "1.05", // Adjust line height
              }}
              >

                {team.name} {Math.round(percentage)}%
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ScoreBar;
