// src/components/GameBoard/WinScreen.js

import React, { useEffect, useState, useContext } from 'react';
import ConfigContext from '../../ConfigContext';
import { database } from '../../firebase';
import { ref, onValue } from 'firebase/database';

const WinScreen = ({ winningTeam }) => {
  const config = useContext(ConfigContext);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (!winningTeam) return;

    const gameRestartTimeRef = ref(database, `games/${config.gameId}/gameRestartTime`);

    const updateTimer = (snapshot) => {
      const gameRestartTime = snapshot.val();
      if (gameRestartTime) {
        const interval = setInterval(() => {
          const now = Date.now();
          const timeLeftMs = gameRestartTime - now;
          if (timeLeftMs <= 0) {
            clearInterval(interval);
            setTimeLeft(0);
          } else {
            setTimeLeft(Math.floor(timeLeftMs / 1000));
          }
        }, 1000);

        return () => clearInterval(interval);
      }
    };

    const unsubscribe = onValue(gameRestartTimeRef, updateTimer);

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [winningTeam, config.gameId]);

  if (!winningTeam) return null;

  const teamConfig = config.teams[winningTeam];

  // Format time as mm:ss
  const formatTime = (seconds) => {
    const m = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const s = (seconds % 60).toString().padStart(2, '0');
    return `${m}:${s}`;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 transition-opacity duration-500">
      <div className="bg-white rounded-lg p-8 text-center relative animate-fadeIn">
        <h2 className="text-4xl font-bold mb-4">{teamConfig.name} Win!</h2>
        <p className="text-lg mb-6">
          Congratulations to the {teamConfig.name} team for winning the game!
        </p>
        <p className="text-md mb-6">
          Next game starts in: <span className="font-semibold">{formatTime(timeLeft)}</span>
        </p>
      </div>
    </div>
  );
};

export default WinScreen;
